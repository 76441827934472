import * as React from "react";
import { createContext, ReactNode, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import { fakeAuthProvider, urls } from "./auth";
import { LocalStorageService } from '../helpers/LocalStorage';

import HomePage from "../pages/Home/HomePage";
import Login from "../pages/Login"
import SignUp from "../pages/SignUp";
import Comfirm from "../pages/Confirm";

import TopicForm from "../pages/Form/TopicForm";
import CategorieDetails from "../pages/Form/CategorieDetails";
import TopicList from "../pages/Form/TopicList";
import Deneme from "../pages/Form/Deneme";

import Profile from "../pages/Member/Profile";
import Settings from "../pages/Member/Settings";

const AppRouter = () => {
 
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="/giris" Component={Login} />
        <Route path="/kayit-ol" Component={SignUp} />
        <Route path='/users/confirm/:token' Component={Comfirm} />

        <Route path='/yeni-konu' Component={TopicForm} />
        <Route path='/:categorieId/:categorieName' Component={CategorieDetails} />
        <Route path='/:link' Component={Deneme} />
        <Route path='/topics/:id' Component={TopicList} />

        <Route path='/profilim' Component={Profile} />
        <Route path='/ayarlar' Component={Settings} />

      </Routes>
    </AuthProvider>

  );
};

interface AuthContextType {
  token: any;
  signin: (token: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: ReactNode }) {
  const [token, setToken] = useState<any>(null);

  useEffect(() => {
    const token = LocalStorageService.getAuthToken();
    token && LocalStorageService.setAuthToken(token);
  }, []);

  const signin = (newToken: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setToken(newToken);
      callback();
    });
  };

  const signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setToken(null);
      callback();
    });
  };

  const value = { token, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const token = LocalStorageService.getAuthToken();

  if (!auth.token && !token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (location.pathname === "/") {
    return (
      // <Navigate to={urls.uploadInvoice} state={{ from: location }} replace />
      <Navigate to={urls.homePage} state={{ from: location }} replace />
    );
  }

  return children;
}




export default AppRouter;
