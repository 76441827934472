import React from "react";
import { Form, Input, Checkbox, Button, Typography, Divider } from "antd";

const { Title, Text } = Typography;

const SecuritySettings = () => {

  const onFinish = (values:any) => {
    console.log("Form Değerleri:", values);
  };

  return (
    <div>
      <Title level={3} className="profile-msg-title">
        Şifre Değiştirme ve Güvenlik
      </Title>
      <Divider />

      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          twoFactorAuth: false,
          activityNotifications: true,
        }}
      >
        {/* Şifre Değiştirme Alanı */}
        <Title level={4}>Şifre Değiştirme</Title>

        <Form.Item
          label="Eski Şifre"
          name="oldPassword"
          rules={[{ required: true, message: "Lütfen eski şifrenizi giriniz!" }]}
        >
          <Input.Password placeholder="Eski Şifrenizi Giriniz" />
        </Form.Item>

        <Form.Item
          label="Yeni Şifre"
          name="newPassword"
          rules={[
            { required: true, message: "Lütfen yeni şifrenizi giriniz!" },
            { min: 8, message: "Şifre en az 8 karakter olmalıdır!" },
          ]}
        >
          <Input.Password placeholder="Yeni Şifrenizi Giriniz" />
        </Form.Item>

        <Form.Item
          label="Yeni Şifre Tekrar"
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Lütfen yeni şifrenizi tekrar giriniz!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Şifreler eşleşmiyor!"));
              },
            }),
          ]}
        >
          <Input.Password placeholder="Yeni Şifrenizi Tekrar Giriniz" />
        </Form.Item>

        <Divider />

        {/* Güvenlik Seçenekleri */}
        <Title level={4}>Güvenlik Seçenekleri</Title>

        <Form.Item name="twoFactorAuth" valuePropName="checked">
          <Checkbox>İki Faktörlü Doğrulamayı Etkinleştir</Checkbox>
        </Form.Item>

        <Form.Item name="activityNotifications" valuePropName="checked">
          <Checkbox>Hesap Etkinlik Bildirimlerini Aç</Checkbox>
        </Form.Item>

        {/* Kaydet Butonu */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SecuritySettings;
