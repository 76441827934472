import React, { useState } from "react";
import { Form, Input, DatePicker, Checkbox, Select, Button, Typography, Divider, Row, Col } from "antd";

const { Title, Text } = Typography;
const { Option } = Select;


const AccountSettings = () => {
  const [showBirthYearCheckbox, setShowBirthYearCheckbox] = useState(false);
  const [joinDietClub, setJoinDietClub] = useState(false);

  const onFinish = (values:any) => {
    console.log("Form Değerleri:", values);
  };
  return (
   <>
     <div>
      <Title level={3} className="profile-msg-title">
        Hesap Ayarları Formu
      </Title>
      <Divider />
      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          emailUpdates: true,
          joinDietClub: false,
        }}
      >
        {/* Üye Adı */}
        <Form.Item
          label="Üye Adı"
          name="memberName"
          rules={[{ required: true, message: "Lütfen üye adınızı giriniz!" }]}
        >
          <Input placeholder="Üye Adı" />
        </Form.Item>

        {/* E-posta */}
        <Form.Item
          label="E-posta"
          name="email"
          rules={[
            { required: true, message: "Lütfen e-posta adresinizi giriniz!" },
            { type: "email", message: "Geçerli bir e-posta adresi giriniz!" },
          ]}
        >
          <Input placeholder="E-posta" />
        </Form.Item>

        {/* Güncelleme Seçenekleri */}
        <Form.Item name="emailUpdates" valuePropName="checked">
          <Checkbox>Güncellemelerden haberdar olun</Checkbox>
        </Form.Item>

        {/* Doğum Tarihi */}
        <Form.Item label="Doğum Tarihi" name="birthDate">
          <DatePicker style={{ width: "100%" }} onChange={() => setShowBirthYearCheckbox(true)} />
        </Form.Item>

        {showBirthYearCheckbox && (
          <Form.Item name="showBirthYear" valuePropName="checked">
            <Checkbox>
              Herkesin yaşınızı görmesini istiyorsanız işaretleyiniz.
            </Checkbox>
          </Form.Item>
        )}

        {/* Meslek */}
        <Form.Item label="Meslek" name="profession">
          <Input placeholder="Meslek" />
        </Form.Item>

        {/* Eğitim Durumu */}
        <Form.Item label="Eğitim Durumunuz" name="education">
          <Select placeholder="Eğitim Durumunuzu Seçiniz">
            <Option value="lise">Lise</Option>
            <Option value="lisans">Lisans</Option>
            <Option value="yükseklisans">Yüksek Lisans</Option>
            <Option value="doktor">Doktora</Option>
          </Select>
        </Form.Item>

        {/* Yaşadığınız Yer */}
        <Form.Item label="Yaşadığınız Yer" name="location">
          <Input placeholder="Yaşadığınız Yer" />
        </Form.Item>

        {/* Kulüp ve Abonelik Seçenekleri */}
        <Form.Item name="membershipOptions" label="Kulüp ve Abonelik Seçenekleri">
          <Checkbox.Group>
            <Row>
              <Col span={12}>
                <Checkbox value="motherClub">Anneler Kulübüne Katılmak İstiyorum</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="newsletter">E-Bülten Almak İstiyorum</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="tupBebek">Tüp Bebek</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="babyAndChild">Bebek ve Çocuk</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="esthetic">Estetik</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  value="joinDietClub"
                  onChange={(e) => setJoinDietClub(e.target.checked)}
                >
                  Diyet Kulübüne Katılmak İstiyorum
                </Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        {/* Diyet Bilgileri */}
        {joinDietClub && (
          <>
            <Form.Item label="Boy (cm)" name="height">
              <Input placeholder="Boyunuzu giriniz" />
            </Form.Item>
            <Form.Item label="Kilo (kg)" name="weight">
              <Input placeholder="Kilonuzu giriniz" />
            </Form.Item>
          </>
        )}

        {/* Sağlıklı Yaşam ve Diğer Tercihler */}
        <Form.Item label="Diğer Tercihler" name="preferences">
          <Checkbox.Group>
            <Row>
              <Col span={12}>
                <Checkbox value="healthyLiving">Sağlıklı Yaşam</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="sports">Spor</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="diet">Diyet - Sağlıklı Beslenme</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        {/* Mesaj ve Bildirim Ayarları */}
        <Form.Item name="notificationSettings" label="Bildirim Ayarları">
          <Checkbox.Group>
            <Row>
              <Col span={12}>
                <Checkbox value="messages">Mesaj Almak İstiyorum</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="notifications">Bildirim Almak İstiyorum</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        {/* Gönder Butonu */}
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Formu Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
   </>
  );
};

export default AccountSettings;
