import React, { useState } from "react";
import {  List, Typography, Button, Modal, Input, Avatar } from "antd";

const { Title, Text } = Typography;
const { TextArea } = Input;

const ProfileMessages = () => {
   // Mesajlar - Farklı kişilerden gelen ve giden mesajlar
   const [messages, setMessages] = useState([
    { id: 1, from: "Ali", content: "Merhaba, nasılsınız? Ben de tanışmak isterim, sizi daha iyi tanımak güzel olur.", date: "2024-12-05", direction: "incoming" },
    { id: 2, from: "Ali", content: "Bilgi alabilir miyim?", date: "2024-12-05", direction: "incoming" },
    { id: 3, from: "Me", content: "Tabii ki, size yardımcı olabilirim.", date: "2024-12-05", direction: "outgoing" },
    { id: 4, from: "Ali", content: "Teşekkürler! iyi günler", date: "2024-12-06", direction: "incoming" },
    { id: 5, from: "Me", content: "Rica ederim, iyi günler.", date: "2024-12-06", direction: "outgoing" },
    { id: 6, from: "Defne", content: "Projeyle ilgili bazı sorularım var.", date: "2024-12-07", direction: "incoming" },
    { id: 7, from: "Me", content: "Tabii, sorabilirsiniz.", date: "2024-12-07", direction: "outgoing" },
    { id: 8, from: "Ayşe", content: "Yeni bir iş fırsatım var, ne düşünüyorsunuz?", date: "2024-12-08", direction: "incoming" },
    { id: 9, from: "Me", content: "Harika! Detayları bana iletebilir misiniz?", date: "2024-12-08", direction: "outgoing" },
    { id: 10, from: "Nazan", content: "Sizinle tanışmak isterim.", date: "2024-12-09", direction: "incoming" },
    { id: 11, from: "Me", content: "Ben de tanışmak isterim, sizi daha iyi tanımak güzel olur.", date: "2024-12-09", direction: "outgoing" },
  ]);
 
  const [chatModalVisible, setChatModalVisible] = useState<any>(false);
  const [currentChat, setCurrentChat] = useState<any>([]);
  const [replyText, setReplyText] = useState<any>("");

  // Belirli bir kişiyle mesajlaşmayı aç
  const handleOpenChat = (person:any) => {
    const chatMessages = messages.filter((msg) => msg.from === person || msg.from === "Me");
    setCurrentChat(chatMessages);
    setChatModalVisible(true);
  };

     // Mesaja cevap verme işlemi
  const handleSendMessage = () => {
    if (!replyText.trim()) return;

    const newMessage = {
      id: messages.length + 1,
      from: "Me",
      content: replyText,
      date: new Date().toISOString().slice(0, 10),
      direction: "outgoing",
    };

    setMessages([...messages, newMessage]);
    setCurrentChat([...currentChat, newMessage]);
    setReplyText("");
  };

    // Her kişiden gelen en son mesaj
    const getLatestMessageFrom = (person:any) => {
      const personMessages = messages.filter((msg) => msg.from === person);
      return personMessages[personMessages.length - 1];
    };

    const handleDeleteMessage = (person:any) => {
      console.log('silinencek mesaj kayıtı', person)
    }
  
    // Kişilerin listesi
    const people = ["Ali", "Defne", "Ayşe", "Nazan"];

  return (
    <>
      <div className="profile-msg-main">
          <Title level={4} className="profile-msg-title" >Mesajlarım</Title>
          <List
            dataSource={people}
            renderItem={(person) => {
              const latestMessage = getLatestMessageFrom(person);
              return (
                <List.Item
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="profile-msg-box">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar>
                        {person[0].toUpperCase()}
                      </Avatar>
                      <div>
                        <Text strong>{person}</Text>
                        <br />
                        <Text type="secondary">{latestMessage.content}</Text>
                      </div>
                    </div>
                    <div  className="profile-msg-btn">
                      <Button type="primary" onClick={() => handleOpenChat(person)}>
                        Mesajları Gör
                      </Button>
                      <Button type="primary" onClick={() => handleDeleteMessage(person)}>
                        Tüm Konuşmayı Sil
                      </Button>
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        </div>
  {/* Sohbet Modalı */}
      <Modal
        title="Mesajlaşma Detayı"
        visible={chatModalVisible}
        onCancel={() => setChatModalVisible(false)}
        footer={null}
        className="profile-messages-modal"
        width={700}
        bodyStyle={{height: 550}}
      >
        <div style={{ maxHeight: "400px", overflowY: "auto", padding: "10px 0" }}>
          {currentChat.map((msg: any) => (
            <div
              key={msg.id}
              style={{
                display: "flex",
                justifyContent: msg.direction === "incoming" ? "flex-start" : "flex-end",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  maxWidth: "70%",
                  padding: "10px 15px",
                  background: msg.direction === "incoming" ? "#f0f0f0" : "#983961",
                  color: msg.direction === "incoming" ? "#000" : "#fff",
                  borderRadius: "15px",
                  textAlign: "left",
                }}
                className={msg.direction === "incoming" ? "incoming-typography" : "from-typography"}
              >
                <Text>{msg.content}</Text>
                <br />
                <Text type="secondary" style={{ fontSize: "12px" }}>
                  {msg.date}
                </Text>
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginTop: "10px" }}>
          <TextArea
            rows={2}
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            placeholder="Mesajınızı yazın..."
          />
          <Button
            type="primary"
            onClick={handleSendMessage}
            style={{ marginTop: "10px", float: "right" }}
          >
            Gönder
          </Button>
        </div>
      </Modal>
   </>
  );
};

export default ProfileMessages;
