import React,{useState} from 'react';
import { Layout, Menu, Avatar, Typography, Button, Modal,  Input, Upload, message, Select,Badge } from "antd";
import { EditOutlined, UploadOutlined, BgColorsOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Text } = Typography;

function MyProfile() {
    const { Title, Text } = Typography;
    const { Option } = Select;
    const [profileBackground, setProfileBackground] = useState<any>("#f1f1f1"); 
    const [colorWrite, setColorWrite] = useState(""); 
    const [isStatusModalVisible, setIsStatusModalVisible] = useState<any>(false);
    const [isBackgroundModalVisible, setIsBackgroundModalVisible] = useState<any>(false);
    const [status, setStatus] = useState<any>("");
    const [newStatus, setNewStatus] = useState<any>("");

    const handleBackgroundUpload = (info:any) => {
        const file = info.file;
        if (file) {
          const reader:any = new FileReader();
          reader.onload = (e:any) => {
            setProfileBackground(`url(${e.target.result})`);
            message.success("Arkaplan başarıyla güncellendi!");
          };
          console.log('prprg',profileBackground)
          reader.onerror = () => {
            message.error("Arkaplan yüklenirken bir hata oluştu!");
          };
          reader.readAsDataURL(file); 
        }
        setColorWrite('#fff')
      };
    
      const handleStatusUpdate = () => {
        if (newStatus.trim()) setStatus(newStatus);
        setIsStatusModalVisible(false);
        setNewStatus("");
      };
    
      const handleColorChange = (value:any) => {
        setColorWrite(value); 
      };

    return (
        <>
            <div className="profile-out-div"
                style={{
                    background: profileBackground,
                }}
            >
                <div style={{ width: '120px' }}>
                    <Avatar
                        size={100}
                        src="https://via.placeholder.com/150"
                        style={{ border: "2px solid #1890ff" }}
                    />
                </div>
                <div style={{ width: '100%' }} className="profile-right">
                    <Title level={4} style={{ margin: 0, color: `${colorWrite === "" ? "#983961" : colorWrite}  ` }} className="profile-title">
                        Merhaba Nazliy
                    </Title>
                    <Badge.Ribbon text="Kayıtlı Üye" color="gold" style={{ top: '-33px', right: '-27px' }} />
                    {/* <Text type="secondary">Kayıtlı Üye</Text> */}
                    <div style={{ marginTop: "10px", color: `${colorWrite}` }}>
                        <Text strong style={{ color: `${colorWrite}` }}>Yeni Üye</Text> - <Text style={{ color: `${colorWrite}` }}>Katılım Tarihi: 01/12/2024</Text> -{" "}
                        <Text style={{ color: `${colorWrite}` }}>Son Görülme: 07/12/2024</Text>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <Text strong style={{ color: `${colorWrite}` }}>Durum:</Text> <Text style={{ color: `${colorWrite}` }}>{status}</Text>
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => setIsStatusModalVisible(true)}
                            style={{ color: `${colorWrite}` }}
                        >
                            Düzenle
                        </Button>
                    </div>
                    <div className="background-btn-div">
                        <Button
                            type="default"
                            icon={<BgColorsOutlined />}
                            onClick={() => setIsBackgroundModalVisible(true)}
                        >
                            Profil Arkaplanını Değiştir
                        </Button>
                    </div>

                </div>
            </div>
            <Modal
                title="Durumunuzu Güncelleyin"
                visible={isStatusModalVisible}
                onCancel={() => setIsStatusModalVisible(false)}
                onOk={handleStatusUpdate}
                okText="Güncelle"
                cancelText="İptal"
                className="status-modal-update"
            >
                <TextArea
                    placeholder="Yeni durumunuzu yazın..."
                    value={newStatus}
                    rows={5}
                    onChange={(e) => setNewStatus(e.target.value)}
                ></TextArea>
            </Modal>


            <Modal
                title="Profil Arkaplanını Düzenle"
                visible={isBackgroundModalVisible}
                onCancel={() => setIsBackgroundModalVisible(false)}
                footer={null}
                className="profile-modal-change"
            >
                <Upload
                    beforeUpload={() => false}
                    onChange={handleBackgroundUpload}
                    showUploadList={false}
                >
                    <Button icon={<UploadOutlined />}>Yeni Arkaplan Yükle</Button>
                </Upload>
                <Select
                    defaultValue="#ffffff"
                    style={{ width: 200 }}
                    onChange={handleColorChange}
                >
                    <Option value="#ffffff">Beyaz</Option>
                    <Option value="#f5222d">Kırmızı</Option>
                    <Option value="#1890ff">Mavi</Option>
                    <Option value="#52c41a">Yeşil</Option>
                    <Option value="#faad14">Sarı</Option>
                    <Option value="#722ed1">Mor</Option>
                    <Option value="#000000">Siyah</Option>
                </Select>
            </Modal>
        </>
        
      
    );
}

export default MyProfile;
