import React from "react";
import { Form, Checkbox, Button, Typography, Divider } from "antd";

const { Title } = Typography;

const PrivacySettings = () => {

  const onFinish = (values:any) => {
    console.log("Gizlilik Ayarları:", values);
  };

  return (
    <div>
      <Title level={3} style={{ textAlign: "center" }}>
        Gizlilik Ayarları
      </Title>
      <Divider />

      <Form
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          publicProfile: true,
          membersOnlyProfile: false,
          allowMessagesFromAll: true,
          friendsOnlyMessages: false,
          hideLastSeen: false,
          showActivity: true,
        }}
      >
        {/* Profil Görünürlüğü */}
        <Title level={4}>Profil Görünürlüğü</Title>
        <Form.Item name="publicProfile" valuePropName="checked">
          <Checkbox>Profilim herkes tarafından görülebilir.</Checkbox>
        </Form.Item>
        <Form.Item name="membersOnlyProfile" valuePropName="checked">
          <Checkbox>Profilim sadece üyeler tarafından görülebilir.</Checkbox>
        </Form.Item>

        <Divider />

        {/* Mesaj Alımı */}
        <Title level={4}>Mesaj Alımı</Title>
        <Form.Item name="allowMessagesFromAll" valuePropName="checked">
          <Checkbox>Herkes bana mesaj gönderebilir.</Checkbox>
        </Form.Item>
        <Form.Item name="friendsOnlyMessages" valuePropName="checked">
          <Checkbox>Sadece arkadaşlar bana mesaj gönderebilir.</Checkbox>
        </Form.Item>

        <Divider />

        {/* Aktivite Görünürlüğü */}
        <Title level={4}>Aktivite Görünürlüğü</Title>
        <Form.Item name="hideLastSeen" valuePropName="checked">
          <Checkbox>Son çevrimiçi durumum gizlensin.</Checkbox>
        </Form.Item>
        <Form.Item name="showActivity" valuePropName="checked">
          <Checkbox>Gönderdiğim mesajlar ve yorumlar görünür olsun.</Checkbox>
        </Form.Item>

        {/* Kaydet Butonu */}
        <Divider />
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PrivacySettings;
